@charset "utf-8";

$turquoise: #009688; // primary: teal 500
$blue: #03a9f4; // link: light blue 500
$cyan: #00bcd4; // info: cyan 500
$green: #4caf50; // success: green 500
$yellow: #ffc107; // warning: amber 500
$red: #ff5722; // danger: deep orange 500

$notification-radius: 0px;

$scheme-invert: #000000;
$modal-background-background-color: rgba($scheme-invert, .56);

@import "../../node_modules/bulma/bulma.sass"

